<template>
  <div class="app-container v">
    <div class="head-container">
      <quick-select v-model="query.sellerId" filterable url="api/purchase/seller" placeholder="供应商" value-field="belongTo" display-field="belongToName" class="filter-item" style="width: 180px;" auto-select-first-option @change="toQuery" />
      <!-- <el-input v-model="query.itemNum" class="filter-item" :maxlength="50" placeholder="产品ERP编码搜索" @keypress.enter.native="toQuery" clearable style="width: 150px;" /> -->
      <el-input v-model="query.itemName" class="filter-item" :maxlength="50" placeholder="产品名称搜索" @keypress.enter.native="toQuery" clearable style="width: 250px;" />
      <el-input v-model="query.itemModel" class="filter-item" :maxlength="50" placeholder="产品规格搜索" @keypress.enter.native="toQuery" clearable style="width: 150px;" />             
      <el-button class="filter-item" type="success" icon="el-icon-search" @click="toQuery">搜索</el-button>
      <el-button class="filter-item" size="mini" type="info" icon="el-icon-download" :loading="downloadLoading" @click="toDownload">导出</el-button>
      <el-button class="filter-item" size="mini" type="info" icon="el-icon-refresh-left" @click="toQuery">刷新</el-button>
    </div>

    <el-table v-loading="loading" row-key="id" :data="data" height="100px">
      <el-table-column prop="ItemCode" label="产品编码" />
      <el-table-column prop="ItemName" label="产品名称" />
      <el-table-column prop="ItemModel" label="规格型号" />
      <el-table-column prop="OnHand" label="库存" />
      <el-table-column prop="IsCommit" label="已承诺" />
      <el-table-column prop="OnOrder" label="已订购" />
      <el-table-column label="可用">
        <template slot-scope="scope">{{scope.row.OnOrder+scope.row.OnHand-scope.row.IsCommit}}</template>
      </el-table-column>
    </el-table>

    <el-pagination :total="total" :current-page="page + 1" style="margin-top: 8px;" layout=" prev, pager, next, sizes,jumper" @size-change="sizeChange" @current-change="pageChange" />
  </div>
</template>


<script>
import checkPermission from "@/utils/permission";
import initData from "@/mixins/initData";
import { download } from "@/api/data";
import { downloadFile } from "@/utils/index";

export default {
  mixins: [initData],
  data() {
    return {
      downloadLoading: false,
    };
  },
  created() {
    this.init();
  },
  methods: {
    checkPermission,
    beforeInit() {
      this.url = "api/stock/erp";
      let query = JSON.parse(JSON.stringify(this.query));
      this.params = Object.assign(query);
      return true;
    },
    toDownload() {
      if (!this.total) return;
      this.downloadLoading = true;
      this.params.total = this.total;
      download("api/stock/erp/download", this.params)
        .then((result) => {
          downloadFile(result, "进口产品库存", "xlsx");
          this.downloadLoading = false;
        })
        .catch((err) => {
          // this.$message.error(err.message || "操作失败，请稍候再试。");
          this.downloadLoading = false;
        });
    },
  },
};
</script>